@import '../../../styles/customMediaQueries.css';

.root {
  composes: marketplaceModalFormRootStyles from global;

  justify-content: flex-start;

  @media (--viewportMedium) {
    justify-content: space-between;
  }
}

.name {
  display: flex;
  justify-content: space-between;
  margin-top: 24px;

  @media (--viewportMedium) {
    margin-top: 32px;
  }
}

.emailRoot {
  margin-top: 28px;
}

.lastNameRoot {
  margin-top: 28px;
}

.userNameRoot {
  margin-top: 28px;
}

.password {
  margin-top: 28px;
}

.bottomWrapper {
  margin-top: 24px;
  margin-bottom: 24px;
}

.bottomWrapperText {
  composes: marketplaceModalBottomWrapperText from global;
}

.termsText {
  color: #212529;
  font-size: 12px;
  line-height: 14px;
}

.termsLink {
  color: #212529;
  font-size: 12px;
  line-height: 14px;
  text-decoration: underline;
  cursor: pointer;
}

.signUpLabel {
  font-weight: var(--fontWeightMedium);

  @media (--viewportMedium) {
    margin-top: 0;
  }
}

.logInQuestion {
  color: var(--matterColorDark);
  margin-bottom: 30px;
}

.logInLabel {
  font-weight: var(--fontWeightMedium);
  color: var(--marketplaceColor);
}

.logInLabel:hover {
  cursor: pointer;
}
