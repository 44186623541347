@import '../../../styles/customMediaQueries.css';

.root {
  composes: marketplaceModalFormRootStyles from global;

  justify-content: flex-start;

  @media (--viewportMedium) {
    justify-content: space-between;
  }
}

.password {
  composes: marketplaceModalPasswordMargins from global;
}

.bottomWrapper {
  margin-top: 16px;
}

/* Adjust the bottomWrapper's margin to match modals without the helper text */
.bottomWrapperText {
  composes: marketplaceModalBottomWrapperText from global;
}

.recoveryLink {
  /* composes: marketplaceModalHelperLink from global; */
  font-size: 12px;
  line-height: 14px;
  text-decoration: underline;
  color: var(--matterColorDark);
}

.recoveryLinkInfo {
  composes: marketplaceModalHelperText from global;
}

.passwordRecoveryLinkWrapper {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 16px;
}

.logInLabel {
  font-weight: var(--fontWeightMedium);
}

.signUpQuestion {
  color: var(--matterColorDark);
  margin-bottom: 30px;
  line-height: normal;
}

.signUpLabel {
  font-weight: var(--fontWeightMedium);
  color: var(--marketplaceColor);
}

.signUpLabel:hover {
  cursor: pointer;
}
