@import '../../../styles/customMediaQueries.css';

.root {
  composes: marketplaceModalFormRootStyles from global;

  justify-content: flex-start;

  @media (--viewportMedium) {
    justify-content: space-between;
  }
}

.name {
  display: flex;
  justify-content: space-between;
  margin-top: 24px;

  @media (--viewportMedium) {
    margin-top: 32px;
  }
}

.firstNameRoot {
  margin-top: 24px;

  @media (--viewportMedium) {
    margin-top: 32px;
  }
}

.lastNameRoot {
  margin-top: 24px;

  @media (--viewportMedium) {
    margin-top: 32px;
  }
}

.userNameRoot {
  margin-top: 24px;

  @media (--viewportMedium) {
    margin-top: 32px;
  }
}

.password {
  composes: marketplaceModalPasswordMargins from global;
}

.bottomWrapper {
  margin-top: 24px;
  margin-bottom: 24px;
}

.bottomWrapperText {
  composes: marketplaceModalBottomWrapperText from global;
}

.termsText {
  color: #212529;
  font-size: 12px;
  line-height: 14px;
}

.termsLink {
  color: #212529;
  font-size: 12px;
  line-height: 14px;
  text-decoration: underline;
  cursor: pointer;
}
