.root {
  margin-bottom: 16px;
}

.frameSizeModalTitle {
  font-size: 24px;
  line-height: 32px;
  margin: 0;
}

.frameSizeModalInfoBox {
  margin-top: 16px;
  color: var(--matterColorDark);
  line-height: 22px;
}

.frameSizeTable {
  width: 100%;
  border-collapse: collapse;
  margin-top: 32px;
}

.frameSizeTableHead {
  font-size: 16px;
  line-height: 22px;
  font-weight: var(--fontWeightRegular);
  padding-bottom: 6px;
  color: var(--matterColorDark);
}

.frameSizeTableName {
  text-align: left;
}

.frameSizeTableOptionWrapper {
  border-top: 1px solid #dee2e6;

  color: var(--matterColorDark);
  font-size: 16px;
  line-height: 22px;
  font-weight: var(--fontWeightRegular);
}

.frameSizeTableOption {
  padding: 8px 0;
}

.labelWrapper {
  display: flex;
  flex-direction: row;
}

.infoIcon {
  width: 18px;
  height: 18px;
  padding: 4px;
}

.infoIcon:hover {
  cursor: pointer;
}

.hintWrapper {
  margin-bottom: 8px;
}

.hintText {
  color: #6b7280;
}

.mandatoryComponent {
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0em;
  color: #d03f41;
}
